@font-face {
  font-family: "Boowie";
  src: url("boowie/boowie.woff2") format("woff2"),
    url("boowie/boowie.woff") format("woff"),
    url("boowie/boowie.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "July It";
  src: url("julyIt/julyIt.woff2") format("woff2"),
    url("julyIt/julyIt.woff") format("woff"),
    url("julyIt/julyIt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

@font-face {
  font-family: "EyesomeScript";
  src: url("eyesome/font.woff2") format("woff2"),
    url("eyesome/font.woff") format("woff");
}
