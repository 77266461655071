/* Add these lines at the top of src/index.css */
@import "~primereact/resources/themes/saga-blue/theme.css";
@import "~primereact/resources/primereact.min.css";
@import "~primeicons/primeicons.css";

@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: "Cormorant", serif, "Cormorant", serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "EyesomeScript", serif;
}

p {
  font-family: "Cormorant", serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
}

h2 {
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 1.2;
  margin: 0;
}

h3 {
  font-family: "Boowie", serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 1.2px;
  margin: 0;
}

h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

h6 {
  font-weight: 400;
  margin: 0;
}
