:root {
  --background-color-light: #f2f2f2;
  --text-color-light: #000000;

  --background-color-dark: #272727;
  --text-color-dark: #ffffff;
  --error-msg: #a51010;
}

body.light-theme {
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

body.dark-theme {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}
